
.TaskAddPanel .form {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    color: #97a1b1;
    font-size: 12px;
    margin-right: 24px;
}

.TaskAddPanel  .kvp {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 22px;
}

.TaskAddPanel  .kvp .label {
    display: flex;
    align-items: center;
    color: #97a1b1;
    font-weight: 600;
    font-size: 12px;
    width: 120px;
}

.TaskAddPanel  .value {
    color: #262932;
    font-weight: 500;

    display: flex;
    flex-direction: row;
    align-items: center;
    flex:1;
}


.TaskAddPanel .saveBtn{
    background: #3661ee;
    display:flex;
    color: #ffffff;font-size: 16px;font-weight: 500;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 24px 24px;
    height: 46px;
    border-radius: 4px;
}
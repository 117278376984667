.TopBar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /*padding: 0.5rem 1rem;*/
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fff;
    border-bottom: 1px solid #e6e6e6;
}
.TopBar .foldIcon{
    width:32px;height:32px;
}
.TopBar .gap{
    flex:1;
}
.TopBar .createBtn{
    margin-right: 20px;
}
.TopBar .welcomeTf{

    height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #5a5d67;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 16px;
    margin-right: 10px;
}
.TopBar .nickname{
        color: rgba(54, 97, 238, 1);

}
.TopBar .userbref{
    display: flex;
    flex-direction: row;
    align-items: center;

}
.TopBar .usericon{
    border-radius: 50%;
    width: 36px;
    height: 36px;
    /*margin-right: 12px;*/
}
/*.TopBar .username{*/

/*    width: 68px;*/
/*    height: 25px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    color: rgba(38, 41, 50, 1);*/
/*    font-family: PingFang SC;*/
/*    font-weight: 600;*/
/*    font-size: 18px;*/
/*}*/
/*.TopBar .userrole{*/

/*    width: 61px;*/
/*    height: 20px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    color: rgba(54, 97, 238, 1);*/
/*    font-family: PingFang SC;*/
/*    font-weight: 500;*/
/*    font-size: 14px;*/
/*}*/

.TopSearch{
    display:flex;
    flex-direction: row;
    align-items: center;
    flex:1;
    color: #fff;
    max-width: 500px;
    justify-content: flex-end;
    justify-self: flex-end;
    position: relative;
}
.TopSearch .Select{
    margin:0 20px 0 5px;
    width:100%;
}
.TopSearch .searchicon{
    position: absolute;
    right:30px;
    color:#bbb;
    z-index: 1;
}
.DashboardPage{
}

.DashboardPage .groupborder{
    /*border: solid 1px #dfe6ec;*/
}

/*schedulechart*/
.DashboardPage .schedulechart{
    background-color: #fff;
}

.ProjectMemberEdit{
    display:flex;
    flex-direction: column;
    align-items: stretch;

}


/*region 添加按钮*/
.ProjectMemberEdit .createBtn{
    border-radius: 4px;
    background: #3661ee;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    padding:8px 15px;
    min-height: 30px;
    margin-top: 8px;
}
.ProjectMemberEdit .createBtn:hover{
    opacity: 0.8;
    cursor: pointer;
}
.ProjectMemberEdit .createBtnIcon{
    width:12px;height:12px;
    margin-left: 4px;
}
/*endregion*/

.ProjectMemberEdit .row{
    height: 32px;
    border-radius: 4px;
    background: #3661ee0f;
    margin-bottom: 16px;
    padding: 6px 0;
    align-items: center;
}
.ProjectMemberLi{
    display:flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;

}
.ProjectMemberLi .head{
    margin-right: 6px;
    width: 32px;
    height:32px;
}
.ProjectMemberLi .head img{
    width: 32px;
    height:32px;
    border-radius: 50%;
}
.ProjectMemberLi .name{
    color: #262932;
    font-size: 14px;
}
.ProjectMemberEdit .tag-project{
    margin-left: 10px;
    padding: 1px 4px;
    display: flex;
    align-items: center;
    color: #3661ee;
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid #3661ee;
    box-sizing: border-box;
    background: #3661ee1a;
}
.ProjectMemberEdit .tag-daily{
    margin-left: 10px;
    padding: 1px 4px;
    display: flex;
    align-items: center;
    color: #15be8c;
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid #15be8c;
    box-sizing: border-box;
    background: #15be8c33;
}
.ProjectMemberEdit .actionBtn{
    margin-left: auto;
    color:#3661EE;
    cursor: pointer;
    margin-right: 10px;
}

.ProjectMemberEdit .Select{
    width: 100%;
}
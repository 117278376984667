.ChartRisk{
    /*aspect-ratio: 1/1;*/
}
.RiskLi{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #efefef;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    border: solid 1px #f9f9f9;
    padding: 5px 10px;
    margin: 0 0 10px;
    font-size: 12px;
    text-align: center;
}
.RiskLi .level1,.RiskLi .level2,.RiskLi .level3{
    border-radius: 50%;
    width:24px;
    height: 24px;
    color:#fff;
    font-size: 14px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.RiskLi .level1{
    background: #ff0000;
}
.RiskLi .level2{
    background: #ffff00;
    color:#000;
}
.RiskLi .level3{
    background: #00ff00;
}
.RiskLi .date{
    width:90px;
    flex:1;

}
.RiskLi .type{    white-space: nowrap;
}
.RiskLi .num{
    width:60px;
    text-align: center;
    text-indent: 5px;
    padding-bottom: 5px;
}
.RiskLi  .StepNum{
    font-size: 28px;
    color: #000;
    font-weight: bold;

}

.RiskLi .reason{
    flex:1;
    text-align: right;
    word-wrap: break-word;
    max-lines: 2;
    text-overflow: ellipsis;
    /*white-space: nowrap;*/

}
@media screen and (max-width: 1440px) {
    .RiskLi .num {
        width: 26px;
        padding-bottom: 2px;
        text-indent: 0;
    }

    .RiskLi  .StepNum{
        font-size: 16px;
        font-weight: bold;
    }
}
.HomeRow{
    min-height: 535px;
    display:flex;
    flex-direction: row;
    align-items: stretch;
    min-width: 880px;
}
/*region 右侧*/
.HomePage .right-card{
    background:#fff;
    height:100%;
}

.HomePage .right-card .zbtitle{
    height: 20px;
    display: flex;
    align-items: center;
    color: #5a5d67;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 14px;
    margin-left: 24px;
    margin-top: 16px;
}
.HomePage .right-card .tuli-row{
    display:flex;
    flex-direction: row;
    margin-left: 24px;
    margin-top: 10px;

}
.HomePage .right-card .tuli-row {
}
/*region 颜色图例（背景色）*/
.HomePage .right-card .tuli {
    padding-left: 8px;
    padding-right: 8px;
    /*min-width: 55px;*/
    height: 25px;
    border-radius: 4px;
    background: #2ce5a3;
    margin-right: 12px;
    display:flex;
    align-items: center;
    justify-content: center;


    text-align: center;
    color: #ffffff;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 12px;
}
.HomePage .right-card .tuli1{background:#2ce5a3;}
.HomePage .right-card .tuli2{background:#f8c925;}
.HomePage .right-card .tuli3{background:#ED2719;}
.HomePage .right-card .tuli4{background:#3661EE;}
/*endregion*/

.HomePage .right-card .zbTableWrap {
    margin-left: 24px;
    margin-top: 10px;
    margin-right: 24px;
    width:auto;
}
.HomePage .right-card .zbTable{
    /*border: solid 1px #eaebef;*/
    width:100%;
    border-collapse: collapse;
}
.HomePage .right-card .zbTable thead th{
    border: solid 1px #eaebef;
    color:#97A1B1;
    padding-top:10px;
    padding-bottom:10px;
    padding-left:10px;
    font-size: 12px;
}
.HomePage .right-card .zbTable  td{
    border: solid 1px #eaebef;
    padding-top:10px;
    padding-bottom:10px;
    padding-left:10px;
    color: #262932;
    font-family: PingFang SC;
    font-size: 14px;
    position: relative;
}

.HomePage .right-card .zbTable  td{
    white-space: nowrap;
}
.HomePage .right-card .zbTable  td.taskname{
    padding-left: 18px;
    white-space: normal;
}
.HomePage .right-card .zbTable .taskLi{
    cursor: pointer;
}
.HomePage .right-card .zbTable .taskLi:hover {
    background: #f3f3f3;
}
.HomePage .right-card .zbTable  td.empty {
    text-align: center;
    color:#97A1B1;
    height: 80px;
}

/*region 单元格左侧的颜色图示 */
.HomePage .right-card .zbTable .tu {
    height:100%;
    position: absolute;
    top:0;left:0;bottom:0;width:8px;
}
.HomePage .right-card .zbTable .tu1 {
    background:   #2ce5a3;
}
.HomePage .right-card .zbTable .tu2 {
    background:   #f8c925;
}
.HomePage .right-card .zbTable .tu3 {
    background:   #ED2719;
}
.HomePage .right-card .zbTable .tu4 {
    background:  #3661EE;
}
/*endregion*/
/*endregion*/

/*region 左侧卡片*/
.HomePage .left-card{
    background:#fff;
    display:flex;
    flex-direction: column;
    height:100%;
}
.HomePage .left-common-ele{

}


.HomePage  .title{
    display: flex;
    align-items: center;
    text-align: center;
    color: #262932;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 16px;
    padding-left: 24px;
    padding-top: 10px;

}
.HomePage .alert{
    width:auto;
    height: 29px;
    border-radius: 4px;
    background: #3661ee1f;
    margin-top: 20px;
    margin-left: 24px;
    margin-right: 24px;

    text-align: center;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3661ee;
    font-family: PingFang SC;
    font-weight: 600;
    font-size: 12px;
}


.HomePage .left-card .list{
    padding-left: 24px;
margin-top: 24px;
    padding-right: 24px;
}
.HomePage .left-card .list .li{
    display:flex;
    flex-direction: row;
    align-items: center;

    height: 44px;
    border-radius: 4px;
    border: 1px solid #eaebef;
    box-sizing: border-box;
    background: #ffffff;
    margin-bottom: 16px;
    position: relative;
    cursor: pointer;
}
.HomePage .left-card .list .li:hover {
    border-color:#97A1B1;
}
.HomePage .left-card .list .li .remindDot{
    width: 6px;height:6px;
    border-radius: 50%;
    background-color: #ff0000;
    position: absolute;
    top:50%;
    margin-top: -4px;
    left:6px;
}
.HomePage .left-card .list .li .icon{
    width: 16px;height:16px;
    margin-right: 7px;
    margin-left: 15px;
}
.HomePage .left-card .list .li .icon img{
    width: 100%;height:100%;
}
.HomePage .left-card .list .li .text{
    margin-right: auto;

    display: flex;
    align-items: center;
    color: #262932;
    font-family: PingFang HK;
    font-size: 14px;
}
.HomePage .left-card .list .li .btn {
width: 20px;height:20px;margin-right: 12px;
}
.HomePage .left-card .list .li .btn img{
    width: 100%;height:100%;
}
/*endregion*/
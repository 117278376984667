/*region 单元格左侧的颜色图示 */
.tasksTable .tu {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 8px;
}

.tasksTable .tu1 {
    background: #2ce5a3;
}

.tasksTable .tu2 {
    background: #f8c925;
}

.tasksTable .tu3 {
    background: #ED2719;
}

.tasksTable .tu4 {
    background: #3661EE;
}

/*endregion*/

.tasksTable .dropDownLiIcon {
    width: 18px;
    height: 18px;
}

.tasksTable .dropDownIcon {
    width: 24px;
    height: 24px;
    padding-left: 5px;
    margin-left: auto;
    padding-right: 5px;
}

.tasksTable .dropDownLi {
    width: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.tasksTable .taskNameDropDownWrapper{
    /*background-color: #ff0;*/
    top:50%;margin-top: -12px;
    position: absolute;
    right:0px;
}
.tasksTable .taskNameDropDown{
    margin:auto;
}
.tasksTable .taskNameDropDownMenu{
    margin-top: -5px;
}

.tasksTable .taskNameCell{
}

.tasksTable .el-table th{
    background: inherit;
}
.tasksTable .el-table th .cell {
    background: inherit;
    color:#97A1B1;
    font-size: 12px;
    line-height: 15px;
}
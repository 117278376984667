.tasksTable .tabPanes .el-tabs__header{
    margin-bottom: 0;
    border-bottom: none;
}
.tasksTable .el-table tr.taskCriticalRow   td{
    background-color: #ed27191a !important;
    font-weight: bold;
}
.tasksTable .el-table .cell{
    overflow: visible;

}

.WeekDetailPage .el-table__body-wrapper,
.WeekDetailPage .el-table .el-table__body ,
.WeekDetailPage .el-table__empty-block
{
    min-height: 380px;
}
.WeekDetailPage .el-table__empty-block{
    margin-top: -380px;
}

.WeekDetailPage .secondRow{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 24px;
margin-right: 24px;
}


.WeekDetailPage .tuli-row{
    display:flex;
    flex-direction: row;

}
/*region 颜色图例（背景色）*/
.WeekDetailPage .tuli {
    padding-left: 8px;
    padding-right: 8px;
    /*min-width: 55px;*/
    height: 25px;
    border-radius: 4px;
    background: #2ce5a3;
    margin-right: 12px;
    display:flex;
    align-items: center;
    justify-content: center;


    text-align: center;
    color: #ffffff;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 12px;
}
.WeekDetailPage .tuli1{background:#2ce5a3;}
.WeekDetailPage .tuli2{background:#f8c925;}
.WeekDetailPage .tuli3{background:#ED2719;}
.WeekDetailPage .tuli4{background:#3661EE;}
/*endregion*/
html{
  background: #fff;
  scroll-behavior: smooth;
}
body {
  background: #ffffff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: justify;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


* {
  padding: 0px;
  margin: 0px;
}


input,
button,
select,
textarea {
  outline: none;
  -webkit-appearance: none;
}
* a{
  text-decoration: none;
  color : inherit;
}


.crm-table{
  width: 100%;
  border: solid 1px #333333;
}
.crm-table tr{
  border-top: solid 1px #ccc;
}
.crm-table tr.alt{
  background: #f1f1f1;
}


.row{
  display: flex;
  flex-direction: row;
}
.leftCol{
  display:flex;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.rightCol{
  display: flex;
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  flex:1;
  flex-direction: column;
}
.red{
  color: #ff0000;
}
.green{
  color: #00ff00;
}
.blue{
  color: #20a0ff;
}

.bold{
  font-weight: bolder;
}

.fieldCol{
  line-height: 2em;
}


#dashboard-chart1{
  width: 100%;
  height: 500px;
}

.info-row{
  background-color: #ff6666 !important;
}


.main-wrapper{
    display: flex;
    flex-direction: row;
    /*justify-content: stretch;*/
    /*align-items: center;*/
    width: 100%;
    height: 100%;
  min-height: 100vh;
}
.nav-wrapper{
  width: 200px;
  min-height: 100vh;
  background-color: #324157;
}
.right-wrapper{
  flex:1;
    /*display: flex;*/
  background: rgba(242, 244, 245, 1);
  overflow-x:hidden;
}
.top-bar-wrapper{
  background-color: #fff;
}
.main-content-wrapper{
    flex:1;
  background: rgba(242, 244, 245, 1);
}

.bg-purple-dark{
    background-color: #7f00ff;
}
.bg-purple{
  background-color: #20a0ff;
}
.bg-purple-light{
    background-color: #b2b2b2;
}
.grid-content{
  height: 20px;
  border-radius: 5px;
}
.spacer{
  flex:1;
  height: auto;
}
.pageWithPadding{
    padding: 1rem;
}
.pageCard{

  border: solid 1px #dfe6ec;
  border-bottom: none;
  margin-bottom: 20px;
  background-color: #fff;
  padding-bottom: 20px;
}
.cardWithPadding{
  padding-left: 24px;
  padding-right: 24px;
}
.tableTitleRow{
  background-color: #fff;
  padding-top: 1em;
  padding-bottom: 1em;
  display:flex;
  flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.tableTitle{
  padding-left: 1em;
  font-weight: bold;
  line-height: 36px;
  font-size: 20px;
}
.tableTitleRight{
  text-align: right;
  padding-right: 1em;
}
.form-upload{
    display: flex;
    flex-direction: column;
  width: 100%;
}
.form-upload .el-upload-dragger{
  width: 100%;
}
.form-upload .icon{
  font-size: 67px;
  margin: 40px 0 16px;
  line-height: 50px;
  color :#97a8be;
}
/*让日期选择器不要固定宽度*/
.el-date-editor.el-input{
  width: auto !important;
}
.userTag{
  margin-right: 2px;
}
.userTag img{
  width: 14px;
  height: 14px;
  border-radius: 50%;
  vertical-align: middle;
  padding-bottom: 2px;
  margin-right: 2px;
}

.chartCard{
  background-color: #fff;

  display:flex;
  flex-direction: column;
  margin-top: 10px;
}
.chartCardHead{
    display:flex;
    flex-direction: row;
    align-items: center;
    padding: 1em 1em 0.5em 1em;
}

.chartCardHead img {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.chartCardBody{
  padding:10px;
  /*aspect-ratio: 1/1;*/
}
.chartCardBody .chart{
  /*aspect-ratio: 1/1;*/

  /*margin:auto;*/
  /*max-width: 290px;*/
}
.overflowscroll{
  overflow: scroll;
}

/*让tabs不产生底部间距 = <Tabs className="shortTabs" ... */
.shortTabs .el-tabs__header{
  margin-bottom: 0 !important;
}
#chart_schedule_container{
  background-color: #fff;
}

/*让element主题色变色*/
.main-wrapper .el-button--primary{
  background-color: rgba(54, 97, 238, 1);
  border-color: rgba(54, 97, 238, 1);
}
.main-wrapper .el-button--text{
  background: none;
    border: none;
}
.main-wrapper .el-button--primary:hover,
.main-wrapper .el-button--primary:focus{
    background-color: rgba(54, 97, 238, .9);
    border-color: rgba(54, 97, 238, .9);
}

.main-wrapper .el-radio-button__orig-radio:checked+.el-radio-button__inner
{

  background-color: rgba(54, 97, 238, 1);
  border-color: rgba(54, 97, 238, 1);
  box-shadow:-1px 0 0 0 rgba(54, 97, 238, 1) ;
}
.el-tabs__item{
  font-weight: 600;
}
.el-tabs__item.is-active{
  color:rgba(54, 97, 238, 1) !important;
}
.el-tabs__active-bar{
  background-color: rgba(54, 97, 238, 1) !important;
}

/*默认文字按钮色*/
.main-wrapper .el-button--text{
  color:#3661EE;
}
.main-wrapper .el-button--text.delete{
  color:#E91417;
}

.Select.el-select{
  display:block;
}

/*fix 恢复 表格最下方 summary行的底部线*/
.el-table__fixed-footer-wrapper tbody td{
  border-bottom: 2px solid #dfe6ec;
}

/* 自定义重写警告toast的显示层级最高 */
.topWarning{
  z-index: 9999;
}
.WeekGraphCard{
    background-color: #fff;
    /*margin-right: 1em;*/
    margin-bottom: 10px;
    display:flex;
    flex-direction: column;
    position: relative;
}
.WeekGraphCard .name{
    margin-top: 14px;

    font-size: 15px;
    font-weight: 800;
    color: #757c88;
    margin-bottom: 5px;
    text-align: left;
    flex:1;
    margin-left: 20px;

}
.WeekGraphCard .icon{
    width:28px;height:28px;
    position: absolute;
    top:10px;right:10px;
}
.WeekGraphCard .val{
    font-size: 28px;
    font-weight: 500;
    color: #333;
    margin-bottom: 12px;
    text-align: left;
    margin-left: 20px;
    flex:1;
    display:flex;
    flex-direction: row;
    align-items: center;
}
.WeekGraphCard .info{
    font-size: 12px;
    font-weight: 500;
    color: #999;
    margin-bottom: 0.5em;
    text-align: left;
    margin-left: 20px;
    flex:1;
}
.WeekGraphCard .percent{
    font-size: 12px;
    margin-left: 12px;
}
.WeekGraphCard .forward{
    color :#4EECBB;
}
.WeekGraphCard .delay{
    color :#FF3E2D;

}
.WeekGraphCard .intime{

}
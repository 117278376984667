
.DailyPage .rbTableWrap {
    margin-left: 24px;
    margin-top: 10px;
    margin-right: 24px;
    width:auto;
}
.DailyPage .rbTable{

    width:100%;
    border-collapse: collapse;
}


.DailyPage .rbTable  td.sectionHead.first{
    border-top: 0.5px solid #2e2e31;
}
.DailyPage .rbTable  td.sectionHead{
    padding-top:10px;
    padding-bottom:10px;
    padding-left:10px;
    text-align: center;
    background: #3661ee33;
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    border-top:none;

}

.DailyPage .rbTable  td{
    border: 0.5px solid #2e2e31;
    padding-left:10px;
    padding-right:10px;
    font-size: 14px;
    position: relative;
    color: #000000;
}

.DailyPage .rbTable .th{

    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 600;}

.DailyPage .rbTable  td .inputTfRow{
    display:flex;
    flex-direction: row;
    align-items: center;
}
.DailyPage .rbTable  td .label{
    display:inline-block;
    padding-top:10px;
    padding-bottom:10px;
    font-weight: 600;
    font-size: 14px;

}
.DailyPage .rbTable  td .val{
    display:inline-block;
    flex:1;
    width:100px;
    /*有flex ： 1 的情况下 width是最小宽度的意思； 若width为auto则表格会超出100%宽 */
    height:auto;
    background: transparent;
    border: transparent;
    appearance: none;
    padding-top:10px;
    padding-bottom:10px;
    font-size: 14px;

}
.DailyPage .rbTable  td .valSel{
    /*width:100px;*/
    background: transparent;
    border: transparent;
    appearance: auto;
}


.DailyPage textarea{
    appearance: none;
    flex:1;
    width: 100%;
    display:flex;
    background: transparent;
    border: transparent;
    padding-top:10px;
    padding-bottom:10px;
    min-height: 72px;

    color: #000000;
    font-size: 14px;
    line-height: 24px;
}
.DailyPage .equipmentTr{
    position:relative;
}
.DailyPage .dropDownWrapper{
    position: absolute;
    /*background-color: #ff0;*/
top:50%;
    margin-top: -12px;
    left : -24px;
}
.DailyPage .equipmentDropdown{}
.DailyPage .equipmentDropdownMenu{
    margin-top: -5px;
}
.DailyPage .dropDownWrapper .dropDownIcon{
    width:24px;height:24px;
}
.DailyPage .dropDownWrapper .dropDownLi{
    color: #e91417;
}

.DailyPage .btnsRow{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding:18px 24px 50px 0;
}
.DailyPage .saveBtn{

    width: 96px;
    height: 46px;
    border-radius: 4px;
    background: #3661ee;
    display:flex;
    align-items: center;
    justify-content: center;
    color :#fff;
    font-size: 16px;

    margin-left: 24px;
    cursor: pointer;
}
.DailyPage .saveBtn.disabledBtn{
    background: #7e7e7e;
    cursor: not-allowed;
}

.DailyPage .logo{
    width: 154px;
    height: 34px;
    margin: 18px auto 18px 24px;

}
.DailyPage .logo img{
    width:100%;height:100%;
}

.DailyPage .sharedTitleRow{
    margin: 18px 24px 18px 24px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.DailyPage .sharedTitleRow .logo{
    margin-left: 0;
}
.DailyPage .sharedTitleRow .tableTitle{
    color: #000000;
    font-weight: 500;
    font-size: 14px;
}
.DailyPage .sharedTitleRow .btns{
    display:flex;flex-direction: row;
}
.DailyPage .sharedTitleRow .topbtn{
    width: 108px;
    height: 34px;
    border-radius: 4px;
    border: 1px solid #3661ee;
    color : #3661EE;
    background-color: #fff;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-left: 20px;
    cursor: pointer;
}
.DailyPage .sharedTitleRow .topbtn.primary{
    background-color: #3661ee;
    color:#fff;
}
.DailyPage .sharedTitleRow .topbtn img{
    width: 18px;height:18px;margin-right: 4px;
}
.DailyPage .sharedHeadTitle{
    margin: 18px 24px 32px 24px;
    font-weight: 600;
    font-size: 28px;
    color:#fff;
    height: 63px;
    background: #3661ee;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.DailyPage .addRysbBtn{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;

    color: #3661ee;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}
.DailyPage .addRysbBtn img{
    width: 20px;height:20px;
}
.DailyPage .sum{

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #000000;
    font-weight: 600;
    font-size: 14px;
}

/*region 5施工内容*/
.DailyPage .sgnr-titlerow{
    display:flex;
    flex-direction: row;
    justify-content: space-between;

    color: #000000;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;

    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 6px;
    padding-right: 6px;

}
.DailyPage .sgnrDeleteBtn img{
    width: 16px;height: 16px;
    margin-right: 4px;
}
.DailyPage .sgnrDeleteBtn{
    display:flex;flex-direction: row;
    align-items: center;
    cursor: pointer;
    color: #e91417;
    font-size: 14px;
}
.DailyPage .sgnr-article{


    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 6px;
    padding-right: 6px;
}

.DailyPage .galleryImg{
    margin-top:10px;
    margin-bottom: 10px;
    max-width: 100%;
    max-height: 300px;
    transition:opacity .3s,background-color .3s,transform .3s;
}
.DailyPage .galleryImg:hover {
    opacity: 0.9;
    background-color: #eeeeee;
    transform:   rotate(1deg);
    /*border:solid 1px #f1f1f1;*/
}
/*endregion*/

/*region 施工内容面板*/
.DailyPage .rtfWrapper{
    border: 1px solid #f1f1f1;

}
.DailyPage .rtfToolbar{
    border:none;
    border-bottom: solid 1px #f1f1f1;
}
.DailyPage .rtfEditor{
    line-height: 1em;
    min-height: 80px;
    padding-left: 10px;
    padding-right: 10px;
}
.DailyPage .prompt{

    color: #000000;
    font-weight: 500;
    font-size: 14px;
}

.DailyPage .el-upload-dragger{
    width: 100%;height:100%;
}
.DailyPage .SgnrPanel .dialogImg{
    width:95%;
}
.DailyPage .SgnrPanel .dialogImgWrapper{
    text-align: center;
    margin:10px auto;
}
/*endregion*/

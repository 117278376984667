.GalleryPanel{

    text-align: center;
    margin:10px auto;
    position: relative;
}

.GalleryPanel .dialogImg{
    width:95%;

}

.GalleryPanel .pageBtn{
    width: 50%;
    position: absolute;
    top:0;left:0;
    height: 100%;
    transition:opacity .3s,background-color .3s,transform .3s;
    opacity: 0;
    font-size: 64px;
    color:#fff;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.GalleryPanel .pageBtn.right{
    left:50%;
    justify-content: flex-end;
}
.GalleryPanel .pageBtn:hover {
    background-color: #eeeeee11;
    opacity: 1;
    cursor: pointer;
}

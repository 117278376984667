.SimpleTable{
border:none;
    border-spacing: 0;
    width:100%;
}
.SimpleTable thead{
    background: rgba(242, 244, 245, 1);
    height: 44px;
    border:none;
}
.SimpleTable thead th{
    height: 44px;

}
.SimpleTable * td
,.SimpleTable * th
{
    border:none;
}

.SimpleTable th {
    color: rgba(142, 144, 147, 1);
    font-family: PingFang SC;
    font-size: 14px;
    padding-left:24px;
}
.SimpleTable tbody tr{
    height: 56px;
}
.SimpleTable td{
    padding-left:24px;
    color: rgba(38, 41, 50, 1);
    font-family: PingFang SC;
    font-size: 14px;
}
.TaskEditPage {
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.TaskEditPage .headline {
    display: flex;
    margin-top: 40px;
    margin-left: 24px;
}

.TaskEditPage .status {
    background: #4fc71a;
    border-radius: 4px;
    width: 14px;
    color: #FFFFFF;

    padding: 8px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;

    margin-right: 10px;
}
.TaskEditPage .status1{background:#2ce5a3;}
.TaskEditPage .status2{background:#f8c925;}
.TaskEditPage .status3{background:#ED2719;}
.TaskEditPage .status4{background:#3661EE;}

.TaskEditPage .headline .right {
    display: flex;
    flex-direction: column;
    align-items: start;
    color: #000000;
    font-weight: 600;
    font-size: 20px;

}

.TaskEditPage .basicinfo {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-left: 24px;
    color: #97a1b1;
    font-size: 12px;
}
.TaskEditPage .form {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    color: #97a1b1;
    font-size: 12px;
    margin-right: 24px;
}

.TaskEditPage  .kvp {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 22px;
}

.TaskEditPage  .kvp .label {
    display: flex;
    align-items: center;
    color: #97a1b1;
    font-weight: 600;
    font-size: 12px;
    width: 120px;
}

.TaskEditPage  .value {
    color: #262932;
    font-weight: 500;

    display: flex;
    flex-direction: row;
    align-items: center;
    flex:1;
}

.TaskEditPage .value .head {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #000;
    margin-right: 4px;
}

.TaskEditPage .value .head img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.TaskEditPage .saveBtn{
    background: #3661ee;
    display:flex;
    color: #ffffff;font-size: 16px;font-weight: 500;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 24px 24px;
    height: 46px;
    border-radius: 4px;
}
.TaskEditPage .hr{
    height: 1px;
    width:auto;
    margin-left: 24px;
    margin-right: 24px;
    background: #eaebef;
    margin-bottom: 24px;
}
.TaskEditPage .oprTitle {
    color: #262932;
    font-weight: 600;
    font-size: 16px;
    margin-left: 24px;
    margin-bottom: 16px;
}
.TaskEditPage .oprList {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
}

.TaskEditPage .oprLi {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #262932;
    margin-bottom: 10px;
}

.TaskEditPage .oprLi span {
    color: #3661ee;
    margin-right: 7px;
}

.TaskEditPage .full-width{
    flex:1;width:auto;
}
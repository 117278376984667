


.WeekSharedPage .el-table__body-wrapper,
.WeekSharedPage .el-table .el-table__body ,
.WeekSharedPage .el-table__empty-block
{    min-height: 180px;
}
.WeekSharedPage .el-table__empty-block{
    margin-top: -180px;
}

.recentPanes{
    margin-top: 1em;
    background-color: #fff;
    padding-bottom: 1em;
    border-bottom: solid 1px #dfe6ec;
    margin-bottom: 1em;
    position: relative;
}

/*近期成果*/
.chengguoLi{
    justify-content: space-between;
    align-items: center;

}
.jihuaLi,.chengguoLi{
    display:flex;
    flex-direction: row;

    font-size: 14px;
    padding-left: 1em;
    padding-right: 1em;
    margin-bottom: 0.8em;
    margin-top: 0.5em;
}
.jihuaLi{
    justify-content: flex-start;
    align-items: center;
}
.chengguoDate{
    justify-self: flex-end;
    margin-left: auto;
}
.jihuaLi .delBtn,.chengguoLi .delBtn{
    margin-left: 2em;
}

/* + btn */
.recentPanes .recentPanes_right{
    position: absolute;
    right:10px;
    z-index: 2;
    top: 5px;

}
.OperationPanelDialog .el-dialog__body{
    padding:0;
}
.OperationPanel{
    /*margin-top: -30px;*/
    margin-bottom: 30px;
}
.OperationPanel .headtitle1 {
    /*margin-top: 40px;*/
    margin-left: 24px;
}
.OperationPanel .headtitle1,.OperationPanel .headtitle2 {
    display: flex;
    flex-direction: column;
    align-items: start;
    color: #000000;
    font-weight: 600;
    font-size: 20px;
    margin-left: 24px;
}
.OperationPanel .headtitle2{
    margin-bottom: 24px;
}
.OperationPanel .hr{
    height: 1px;
    width:auto;
    margin-left: 24px;
    margin-right: 24px;
    background: #eaebef;
    margin-bottom: 24px;
}
.OperationPanel .oprTitle {
    color: #262932;
    font-weight: 600;
    font-size: 16px;
    margin-left: 24px;
    margin-bottom: 16px;
}
.OperationPanel .oprList {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
}

.OperationPanel .oprLi {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #262932;
    margin-bottom: 10px;
}

.OperationPanel .oprLi span {
    color: #3661ee;
    margin-right: 7px;
}

.OperationPanel .full-width{
    flex:1;width:auto;
}
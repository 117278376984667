.NavMenu{
    /*height: 100vh;*/
    /*min-height: 600px;*/
    width: 200px;
    height: 100%;

    display:flex;
    flex-direction: column;

    background-color: #262932;
}

.NavMenu .container{
    background-color: #262932;
    position: fixed;
    top:0;bottom:0;left:0;
    width: 200px;
    height: 100vh;
    display:flex;
    flex-direction: column;
    /*justify-content: space-between;*/
}

.NavMenu .scrollable{
    flex:1;
    overflow-y: auto;
}
.NavMenu .gap{
    height : 32px;
}
.NavMenu .pushbottom{
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
    margin-bottom: 20px;
    /*margin-top: auto;*/
}
.NavMenu .pushbottom .navMenuLi{
    justify-content: center;
}
.NavMenu .logo{
    width: 70%;
    max-width: 117px;
    margin:24px auto 24px 10%;
}
.NavMenu .logo img{
    width: 100%;height: auto;
}


.navMenuGroup{
    padding-left: 20px;
    padding-top: 15px;
    font-size: 14px;
    color:#97a8be;
    margin-bottom: 5px;
    padding-right: 5px;
    margin-right: 13px;
    position:relative;
    display:flex;
    flex-direction: row;
    align-items: center;
}
.navMenuGroupBtn{
    margin-left: auto;
}
.navMenuGroupBtn img{
    width: 18px;height:18px;padding:5px;
    opacity:0.8;
}
.navMenuGroupBtn:hover img{
    opacity: 1;
    width:22px;height:22px;padding:3px;
    cursor: pointer;
}

.navMenuLi{
    margin-left: 13px;
    margin-right: 13px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0;
    padding-bottom: 0;
    height: 43px;line-height: 43px;
    margin-top: 5px;
    margin-bottom: 5px;
    color:#8e9093;
    font-size: 14px;
    transition:border-color .3s,background-color .3s,color .3s;
    position:relative;
    cursor: pointer;
    display:flex;flex-direction: row;
    align-items: center;
}
.navMenuLi.is-active{
    background-color: #3f4143;
    color:#fff;
    border-radius: 4px;
}
.navMenuLi:hover{

    background-color: #3f4143;
    color:#fff;
    border-radius: 4px;
}
.navMenuLi .fa{
    padding-right: 8px;
}
.NavMenu .bottombtn1.is-active
,.NavMenu .bottombtn1:hover{
    background-color:#3661ee;
    color:#fff;
}
.NavMenu .bottombtn1 {
    border-radius: 4px;
    color:#8e9093;
    text-align: center;
}

/*左侧小白条*/
.is-active .deco{
    width: 4px;height: 24px;
    border-radius: 2px;
    background-color: #fff;
    position: absolute;
    left:-13px;top:10px;
}
.navIcon{
    width:15px;height:15px;
    padding-right: 8px;
}



/*region dropDown */
.NavMenu .dropDownWrapper{
    position: absolute;
    top:50%;
    margin-top: -18px;
    right:0px;
}
/*此栏目后面的下拉触发按钮 位置*/
.NavMenu  .el-dropdown{
    height: 36px;
}
.NavMenu .equipmentDropdownMenu{
    margin-top: -5px;
}
/*鼠标移动到菜单栏上 就高亮显示此栏目后面的下拉触发按钮*/
.NavMenu .navMenuLi:hover .el-icon-menu{
    /*height: 33px;*/
    color:#ccc;
}
.navMenuLiMoreBtn{
    width:16px;height:16px;
    opacity: 0.5;
    padding-right:10px;
    /*background-color: #ff0;*/

}
.is-active .navMenuLiMoreBtn{
    opacity: 1.0;
}
.NavMenu .dropDownLiIcon {
    width: 18px;
    height: 18px;
    margin-right: 4px;
}


.NavMenu .dropDownLi {
    /*width: 100px;*/
    display: flex;
    flex-direction: row;
    align-items: center;
}
/*endregion*/

.NavMenu .version{
    display:flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-bottom: 0px;
    font-size: 12px;
    color: #8e9093;
    background: #100f0f;
    height:24px;
}
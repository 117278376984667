.ProjectPage .peopleIcon {
    width: 14px;
    height: 14px;
    vertical-align: -2px;
}

.ProjectPage .peopleBtn {
    margin-left: 20px;
    color: #97A1B1;
}

.ProjectPage .peopleBtn:hover {
    /*color: inherit;*/
}
.ProjectPage .ProjectToolbar{
 background-color: #fff;
 display:flex;flex-direction: row;
 align-items: center;
}

.ProjectPage .createBtn{
 /*width: 86px;*/
 /*height: 25px;*/
 border-radius: 4px;
 background: #3661ee;
 display: inline-flex;
 align-items: center;
 justify-content: center;
 text-align: center;
 color: #ffffff;
 font-family: PingFang SC;
 font-weight: 500;
 font-size: 14px;
 margin-left: auto;
    padding:8px 15px;
}
.ProjectPage .createBtn:hover{
 opacity: 0.8;
 cursor: pointer;
}
.ProjectPage .createBtnIcon{
 width:12px;height:12px;
 margin-left: 4px;
}
.ProjectPage .actionBtns{
    display:flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
}
.ProjectPage .actionBtn{
    font-size: 12px;
    color : #3661EE;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-left: 20px;
    cursor: pointer;
    white-space: nowrap;
}
.ProjectPage .actionBtn img{
    width:14px;height:14px;
}
.LarkTaskAllocList{

}
.LarkTaskAllocList .title{
    margin-top: 40px;
    margin-left: 20px;
    height: 28px;
    color: #262932;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 34px;
}

.LarkTaskAllocList .zbTableWrap{

    margin-left: 20px;
    margin-top: 10px;
    margin-right: 24px;
    width:auto;
}


.LarkTaskAllocList .zbTable{
    border: solid 1px #eaebef;
    width:100%;
    border-collapse: collapse;
}
.LarkTaskAllocList .zbTable thead th{
    border: solid 1px #eaebef;
    color:#262932;
    padding-top:10px;
    padding-bottom:10px;
    padding-left:10px;
    padding-right: 10px;
    font-size: 16px;
    font-weight: 500;
    background: #3661ee1a;
    white-space: nowrap;

}
.LarkTaskAllocList .zbTable  td{
    border: solid 1px #eaebef;
    padding-top:10px;
    padding-bottom:10px;
    padding-left:10px;
    color: #262932;
    font-size: 14px;
    position: relative;
    white-space: nowrap;
}

.LarkTaskAllocList .empty{
    text-align: center;
    height: 2em;
}


.LarkTaskAllocList  .role {
    color: #262932;
    font-size: 14px;

    display: flex;
    flex-direction: row;
    align-items: center;
    flex:1;
}
.LarkTaskAllocList .head {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #000;
    margin-right: 6px;
}

.LarkTaskAllocList .head img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.LarkTaskAllocList .name{
    margin-right: 4px;
}
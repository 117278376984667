.graphcards{
    /*margin-top: 10px;*/
}
.graphcards .el-col:last-child .WeekGraphCard{
    /*margin-right: 0;*/
}




/*schedulechart*/
.WeekGraphPage .schedulechart{
    /*margin-top: 1em;*/
    background-color: #fff;

}


.WeekGraphPage .el-table__body-wrapper,
.WeekGraphPage .el-table .el-table__body ,
.WeekGraphPage .el-table__empty-block
{    min-height: 180px;
}
.WeekGraphPage .el-table__empty-block{
    margin-top: -180px;
}

.Dashboard2Page {
}

.Dashboard2Page .tableTitle {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 24px;
    margin-top: 20px;
}
.Dashboard2Page .tableTitle img {
    width: 24px;
    height: 24px;
    margin-right: 5px;
}
.dashboard2-card {
    background-color: #fff;
    margin-bottom: 20px;
    /*max-width: 878px;*/

}

.cardtitle {

    display: flex;
    flex-direction: row;
    align-items: center;

    margin-left: 24px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.cardtitle img {
    width: 18px;
    height: 18px;
    margin-right: 5px;
}

.dashboard2-jcxx-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    /*justify-content: space-between;*/
    margin-left: 24px;
    margin-right: 10px;
    margin-bottom: 10px;
    /*border-bottom: 1px solid #ebebeb;*/
}

.dashboard2-jcxx-label {
    /* 项目编号： */

    /*width: 70px;*/
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
white-space: nowrap;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height */
    display: flex;
    align-items: center;

    /* text3 */
    color: #8E9093;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.dashboard2-jcxx-value {
    /* PEK12-001 */

    /*width: 84px;*/
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height */
    display: flex;
    align-items: center;

    /* text1 */
    color: #262932;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.dashboard2-ztjz-row {
    border-top: solid 1px rgba(242, 244, 245, 1);
    padding-left: 24px;
    padding-right: 24px;
}

.dashboard2-ztjz-item {
    margin-top: 30px;
}

.dashboard2-ztjz-label {
    height: 22px;
    display: flex;
    align-items: center;
    color: rgba(38, 41, 50, 1);
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 14px;
}

.dashboard2-ztjz-value {

    color: rgba(0, 0, 0, 1);
    font-family: PingFang SC;
    font-size: 14px;
    line-height: 150%;
    padding: 18px;
    /*width: 207px;*/
    min-height: 201px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
}

.Rectangle115 {
    display: inline-block;
    margin-right: 8px;
    width: 2px;
    height: 14px;
    background: rgba(54, 97, 238, 1);
}


.Dashboard2Page .coltitle {
    margin-top: 30px;
    margin-bottom: 14px;
    height: 22px;
    display: flex;
    align-items: center;
    color: rgba(38, 41, 50, 1);
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 16px;
}

.Dashboard2Page .coltitle img {
    width: 18px;
    height: 18px;
    margin-right: 5px;

}

.yusuan {
    max-width: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /*justify-content: space-between;*/
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 14px;
white-space: nowrap;
}

.yusuan-content {
    font-size: 28px;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
}


.dashboard2-cbqk-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    margin-bottom: 14px;
}

.dashboard2-cbqk-label {
    min-width: 102px;
    height: 20px;
    color: rgba(0, 0, 0, 1);
    font-family: PingFang SC;
    font-size: 14px;
}

.dashboard2-cbqk-value {
    height: 20px;
    color: rgba(0, 0, 0, 1);
    font-family: PingFang SC;
    font-weight: 600;
    font-size: 14px;
    margin-right: 5px;
}
.dashboard2-cbqk-ellipse{
    width: 8px;height:8px;
    border-radius: 50%;
    background-color: blue;
    margin-right: 9px;
}


/*图表区*/

.dashboard2-chart-row {
    border-top: solid 1px rgba(242, 244, 245, 1);
}
.dashboard2-chartcol1{
    /*padding-bottom: 20px;*/
    padding-right: 24px;
}
.dashboard2-chartcol2{

    border-left: solid 1px rgba(242, 244, 245, 1);
    padding-left: 24px;
    /*padding-bottom: 20px;*/

}
.Dashboard2Page .dashboard2-chart-row .coltitle {
    margin-left: 24px;
    margin-bottom: 0;
}

.Dashboard2Page .chart-center-wrap{
    max-width: 442px;
    margin-left:auto;
    margin-right:auto;
}
.Dashboard2Page .chartbig-center-wrap{
    max-width: 900px;
    margin-left:auto;
    margin-right:auto;
}

.tooltip{
    position: absolute;
    z-index: 1;
    background-color: #ffffff;
    border-radius: 4px;
    border:solid 1px #e1e1e1;
    padding:10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

/*底部项目进展tabbar*/
.dashboard2-tabbarWrapper{
    position:relative;
    margin: auto 24px;
}
.dashboard2-tabbarRightFloat{
    position:absolute;right:0px;bottom:17px;
    z-index: 1;
    display:flex;flex-direction: row;
}
.dashboard2-tabbarRightFloat .btn{
    display:flex;
    align-items: center;
    justify-content: center;
    padding:8px 24px;
    border-radius: 4px;
    background-color: #fff;
    border:solid 1px #e1e1e1;
    box-sizing: border-box;

    font-size: 16px;
    color :#262932;
    margin-left: 16px;

    cursor: pointer;
}
.dashboard2-tabbarRightFloat .btn.active {
    color:#3661ee;
    border-color:#3661ee;
}
.dashboard2-tabbarWrapper .el-tabs__item{
    font-size: 16px;
    margin-bottom: 15px;
}